import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import SiteBreadcrumb from "../../components/layout/SiteBreadcrumb";
import BestFacilities from "../../components/shared/BestFacilities";
import { useSelector, useDispatch } from "react-redux";
import { LOADER_DURATION } from "../../../helpers/Constants";
import {
  showErrorMsg,
  showInfoMsg,
  showSuccessMsg,
  validateAnyFormField,
} from "../../../helpers/ValidationHelper";
import {
  MakeApiCallSynchronous,
  MakeApiCallAsync,
  GetLoadSTRPPublishable,
} from "../../../helpers/ApiHelpers";
import Config from "../../../helpers/Config";
import rootAction from "../../../stateManagment/actions/rootAction";
import OrderSummary from "../../components/cart/OrderSummary";
import {
  makePriceRoundToTwoPlaces,
  makeProductShortDescription,
} from "../../../helpers/ConversionHelper";
import { Helmet } from "react-helmet";

//--strip
import { v4 as uuidv4 } from "uuid";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutStripForm from "../../components/cart/CheckoutStripForm";
import {
  GetCardType,
  GetDefaultCurrencyCode,
  GetDefaultCurrencySymbol,
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
} from "../../../helpers/CommonHelper";

//--Paypal
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import GlobalEnums from "../../../helpers/GlobalEnums";
import axios from "axios";

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRP_PUBLISHABLE_KEY);

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [PaymentMethod, setPaymentMethod] = useState(
    process.env.REACT_APP_STRIPE_PAYMENT_METHOD ?? 5
  );
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TTILE"]);
  const [OrderNote, setOrderNote] = useState("");
  const [CartSubTotal, setCartSubTotal] = useState(0);
  const [ShippingSubTotal, setShippingSubTotal] = useState(0);
  const [OrderTotal, setOrderTotal] = useState(0);
  const [OrderTotalAfterDiscount, setOrderTotalAfterDiscount] = useState(0);
  const [cartProductsData, setCartProductsData] = useState(0);
  const [CouponCode, setCouponCode] = useState("");
  const [IsCouponCodeApplied, setIsCouponCodeApplied] = useState(false);
  const [IsAlreadyDiscountApplied, setIsAlreadyDiscountApplied] =
    useState(false);
  const [CouponCodeCssClass, setCouponCodeCssClass] =
    useState("cart-coupon-code");
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);

  const loginUserDataJson = useSelector((state) => state.userReducer.user);
  const loginUser = JSON.parse(loginUserDataJson ?? "{}");
  const [cardFormData, setCardFormData] = useState({
    number: "",
    expiry_month: "",
    expiry_year: "",
    cvv: "",
  });
  const handleChange = (e) => {
    console.log("first", e.target.value);
    setCardFormData({ ...cardFormData, [e.target.name]: e.target.value });
  };

  const cartJsonDataSession = useSelector(
    (state) => state.cartReducer.cartItems
  );
  const cartItemsSession = JSON.parse(cartJsonDataSession ?? "[]");

  if (
    loginUser == undefined ||
    loginUser.UserID == undefined ||
    loginUser.UserID < 1
  ) {
    navigate("/" + getLanguageCodeFromSession() + "/login");
  }

  if (
    cartItemsSession == undefined ||
    cartItemsSession == null ||
    cartItemsSession.length < 1
  ) {
    showInfoMsg("Your cart is empty");
    navigate("/" + getLanguageCodeFromSession() + "/");
  }

  const GetCouponCodeInfo = async () => {
    if (IsCouponCodeApplied) {
      showInfoMsg("Coupon code is already applied!");
      return false;
    }

    let isValid = validateAnyFormField(
      "Coupon Code",
      CouponCode,
      "text",
      null,
      30,
      true
    );
    if (isValid == false) {
      return false;
    }

    const headersCoupon = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const paramCoupon = {
      requestParameters: {
        CouponCode: CouponCode,
        cartJsonData: JSON.stringify(cartItemsSession),
      },
    };

    const couponResponse = await MakeApiCallAsync(
      Config.END_POINT_NAMES["GET_COUPON_CODE_DISCOUNT"],
      Config["COMMON_CONTROLLER_SUB_URL"],
      paramCoupon,
      headersCoupon,
      "POST",
      true
    );

    if (couponResponse != null && couponResponse.data != null) {
      let copounData = JSON.parse(couponResponse.data.data);
      console.log(copounData);
      if (
        copounData != undefined &&
        copounData.DiscountValueAfterCouponAppliedWithQuantity != undefined &&
        copounData.DiscountValueAfterCouponAppliedWithQuantity > 0
      ) {
        setOrderTotalAfterDiscount(
          OrderTotal - copounData.DiscountValueAfterCouponAppliedWithQuantity ??
            0
        );
        setIsCouponCodeApplied(true);
      } else {
        showErrorMsg("Invalid coupon code!");
      }
    }
  };

  useEffect(() => {
    // declare the data fetching function
    const dataOperationInUseEffect = async () => {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      //--Get cart data
      const paramCart = {
        requestParameters: {
          cartJsonData: JSON.stringify(cartItemsSession),
          recordValueJson: "[]",
        },
      };

      const customerCartResponse = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_CUSTOMER_CART_ITEMS"],
        Config["COMMON_CONTROLLER_SUB_URL"],
        paramCart,
        headers,
        "POST",
        true
      );
      if (customerCartResponse != null && customerCartResponse.data != null) {
        let finalData = JSON.parse(customerCartResponse.data.data);
        console.log(finalData);

        if (finalData != null) {
          setTimeout(() => {
            setCartProductsData(finalData.productsData);
            setCartSubTotal(finalData.cartSubTotal);
            setShippingSubTotal(finalData.shippingSubTotal);
            setOrderTotal(finalData.orderTotal);

            if (
              finalData.productsData.length > 0 &&
              finalData.productsData.some((el) => el.DiscountedPrice > 0)
            ) {
              setIsAlreadyDiscountApplied(true);
            }
          }, 500);
        }
      }
    };

    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    dataOperationInUseEffect().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, []);

  useEffect(() => {
    // declare the data fetching function
    const dataOperationFunc = async () => {
      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["Checkout"],
        null
      );
      if (
        arryRespLocalization != null &&
        arryRespLocalization != undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };
    // call the function
    dataOperationFunc().catch(console.error);
  }, []);

  console.log(Config.SUMUP_API_KEY);

  const handleCheckoutOnSubmit = async (e) => {
    try {
      e.preventDefault();

      let isYes = window.confirm("Do you really want place order?");
      if (isYes) {
        //--start loader
        dispatch(rootAction.commonAction.setLoading(true));

        const headersCoupon = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };

        const sumupRefIdPayload = {
          amount: String(OrderTotal),
          checkout_reference: uuidv4(),
          currency: "GBP",
          merchant_code: "MFKVNSGX",
          return_url: window.location.href,
        };

        const checkoutResponse = await MakeApiCallAsync(
          Config.END_POINT_NAMES["GET_SUMUP_REFERENCEID"],
          Config["COMMON_CONTROLLER_SUB_URL"],
          sumupRefIdPayload,
          headersCoupon,
          "POST",
          true
        );

        console.log(checkoutResponse);

        if (checkoutResponse.data.statusCode === 200) {
          const data = JSON.parse(checkoutResponse.data.data);
          const payload = {
            payment_type: "card",
            card: {
              name: loginUser.FirstName + " " + loginUser.LastName,
              number: cardFormData.number,
              expiry_month: cardFormData.expiry_month,
              expiry_year: cardFormData.expiry_year,
              cvv: cardFormData.cvv,
              last_4_digits: cardFormData.number.slice(-4),
              type: GetCardType(cardFormData.number),
            },
          };

          const sumupcompleteCheckout = await axios.put(
            `https://api.sumup.com/v0.1/checkouts/${data.referenceId}`,
            payload,
            {
              headers: {
                Authorization: `Bearer sup_sk_V7NaggQTTVUozhpVz7rD8I2b4JbdH5eHX`,
              },
            }
          );

          if (sumupcompleteCheckout.data.status === "PAID") {
            const params = {
              requestParameters: {
                paymentOrderConfirmJson: sumupcompleteCheckout.data,
                referenceId: data.referenceId,
                CouponCode: IsCouponCodeApplied == true ? CouponCode : "",
                OrderNote: OrderNote,
                PaymentMethod: 5,
                paymentToken: "",
                recordValueJson: "[]",
                UserID: loginUser.UserID,
                cartJsonData: JSON.stringify(cartItemsSession),
              },
            };

            const headers = {
              Accept: "application/json",
              "Content-Type": "application/json",
            };

            const postOrder = await MakeApiCallAsync(
              Config.END_POINT_NAMES["POST_CUSTOMER_ORDER"],
              Config.COMMON_CONTROLLER_SUB_URL,
              params,
              headers,
              "POST",
              true
            );
            if (postOrder.data.statusCode === 200) {
              showSuccessMsg("Order placed successfully!");
              setTimeout(function () {
                dispatch(rootAction.cartAction.setCustomerCart("[]"));
                dispatch(rootAction.cartAction.SetTotalCartItems(0));
                localStorage.setItem("cartItems", "[]");
              }, 1000);
              navigate(`/${getLanguageCodeFromSession()}/payment/success`);
            } else {
              showErrorMsg("An error occured. Please try again!");
              // navigate(`/${getLanguageCodeFromSession()}/payment/failed`);
            }
          } else {
            navigate(`/${getLanguageCodeFromSession()}/payment/failed`);
            showErrorMsg("An error occured. Please try again!");
          }
        } else {
          showErrorMsg("An error occured. Please try again!");
        }

        //--stop loader
        setTimeout(() => {
          dispatch(rootAction.commonAction.setLoading(false));
        }, LOADER_DURATION);
      }
    } catch (err) {
      showErrorMsg("An error occured. Please try again!");
      console.log(err.message);
      //--stop loader
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);
    }
  };

  return (
    <>
      <Helmet>
        <title>{siteTitle} - Checkout</title>
        <meta name="description" content={siteTitle + " - Checkout"} />
        <meta name="keywords" content="Checkout"></meta>
      </Helmet>

      <SiteBreadcrumb title="Home" path="Checkout" />

      <section className="checkout-area ptb-60">
        <div className="container">
          <form onSubmit={handleCheckoutOnSubmit}>
            <div className="row">
              <div className="col-lg-5 col-md-5">
                <div className="billing-details">
                  <h3 className="title" id="lbl_check_billdetail">
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "Billing Details",
                          "lbl_check_billdetail"
                        )
                      : "Billing Details"}
                  </h3>

                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Country",
                                "lbl_check_country"
                              )
                            : "Country"}
                        </label>
                        <input
                          type="text"
                          name="CountryName"
                          className="form-control"
                          readOnly
                          value={loginUser.CountryName}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "First Name",
                                "lbl_check_fname"
                              )
                            : "First Name"}
                        </label>
                        <input
                          type="text"
                          name="FirstName"
                          className="form-control"
                          readOnly
                          value={loginUser.FirstName}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Last Name",
                                "lbl_check_lname"
                              )
                            : "Last Name"}
                        </label>
                        <input
                          type="text"
                          name="LastName"
                          className="form-control"
                          readOnly
                          value={loginUser.LastName}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-6">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Shipping Address",
                                "lbl_check_shipadrs"
                              )
                            : "Shipping Address"}
                        </label>
                        <input
                          type="text"
                          name="address"
                          className="form-control"
                          readOnly
                          value={loginUser.AddressLineOne}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-6">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "City",
                                "lbl_check_city"
                              )
                            : "City"}
                        </label>
                        <input
                          type="text"
                          name="city"
                          className="form-control"
                          readOnly
                          value={loginUser.CityName}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "State / Province",
                                "lbl_check_province"
                              )
                            : "State / Province"}
                        </label>
                        <input
                          type="text"
                          name="state"
                          className="form-control"
                          readOnly
                          value={loginUser.StateName}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Postcode / Zip",
                                "lbl_check_postcode"
                              )
                            : "Postcode / Zip"}
                        </label>
                        <input
                          type="text"
                          name="zip"
                          className="form-control"
                          readOnly
                          value={loginUser.PostalCode}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Email Address",
                                "lbl_check_email"
                              )
                            : "Email Address"}
                        </label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          readOnly
                          value={loginUser.EmailAddress}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>
                          {LocalizationLabelsArray.length > 0
                            ? replaceLoclizationLabel(
                                LocalizationLabelsArray,
                                "Phone",
                                "lbl_check_phone"
                              )
                            : "Phone"}
                        </label>
                        <input
                          type="text"
                          name="phone"
                          className="form-control"
                          readOnly
                          value={loginUser.MobileNo}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <textarea
                          name="OrderNote"
                          id="OrderNote"
                          cols="30"
                          rows="6"
                          placeholder="Order Notes"
                          className="form-control"
                          value={OrderNote}
                          onChange={(e) => setOrderNote(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-7 col-md-7">
                <div className="order-details">
                  <h3 className="title" id="lbl_check_yrorder">
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "Your Order",
                          "lbl_check_yrorder"
                        )
                      : "Your Order"}
                  </h3>

                  <>
                    {cartProductsData != undefined &&
                    cartProductsData != null &&
                    cartProductsData.length > 0 ? (
                      <>
                        <div className="order-table table-responsive">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col" id="lbl_hdr_check_prdname">
                                  {LocalizationLabelsArray.length > 0
                                    ? replaceLoclizationLabel(
                                        LocalizationLabelsArray,
                                        "Product Name",
                                        "lbl_hdr_check_prdname"
                                      )
                                    : "Product Name"}
                                </th>
                                <th scope="col" id="lbl_hdr_check_price">
                                  {LocalizationLabelsArray.length > 0
                                    ? replaceLoclizationLabel(
                                        LocalizationLabelsArray,
                                        "Price",
                                        "lbl_hdr_check_price"
                                      )
                                    : "Price"}
                                </th>
                                <th scope="col" id="lbl_hdr_check_qty">
                                  {LocalizationLabelsArray.length > 0
                                    ? replaceLoclizationLabel(
                                        LocalizationLabelsArray,
                                        "Quantity",
                                        "lbl_hdr_check_qty"
                                      )
                                    : "Quantity"}
                                </th>
                                <th scope="col" id="lbl_hdr_check_total">
                                  {LocalizationLabelsArray.length > 0
                                    ? replaceLoclizationLabel(
                                        LocalizationLabelsArray,
                                        "Total",
                                        "lbl_hdr_check_total"
                                      )
                                    : "Total"}
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {cartProductsData?.map((data, idx) => (
                                <tr key={idx}>
                                  <td className="product-name">
                                    <Link to="#">
                                      {makeProductShortDescription(
                                        data.ProductName,
                                        50
                                      )}
                                    </Link>
                                  </td>

                                  <td className="product-name">
                                    <span className="unit-amount">
                                      {data.DiscountedPrice != undefined &&
                                      data.DiscountedPrice > 0 ? (
                                        <>
                                          <del style={{ color: "#9494b9" }}>
                                            {GetDefaultCurrencySymbol()}
                                            {makePriceRoundToTwoPlaces(
                                              data.Price
                                            )}
                                          </del>{" "}
                                          &nbsp; {GetDefaultCurrencySymbol()}
                                          {makePriceRoundToTwoPlaces(
                                            data.DiscountedPrice
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {GetDefaultCurrencySymbol()}
                                          {makePriceRoundToTwoPlaces(
                                            data.Price
                                          )}
                                        </>
                                      )}
                                    </span>
                                  </td>

                                  <td className="product-name">
                                    {data.Quantity}
                                  </td>

                                  {(() => {
                                    let itemSubTotal =
                                      (data.DiscountedPrice != undefined &&
                                      data.DiscountedPrice > 0
                                        ? data.DiscountedPrice
                                        : data.Price) * (data.Quantity ?? 1);

                                    return (
                                      <td className="product-total">
                                        <span className="subtotal-amount">
                                          {GetDefaultCurrencySymbol()}
                                          {makePriceRoundToTwoPlaces(
                                            itemSubTotal
                                          )}
                                        </span>
                                      </td>
                                    );
                                  })()}
                                </tr>
                              ))}

                              <tr>
                                <td className="order-subtotal" colSpan={3}>
                                  <span id="lbl_check_cartsubtotal">
                                    {LocalizationLabelsArray.length > 0
                                      ? replaceLoclizationLabel(
                                          LocalizationLabelsArray,
                                          "Cart Subtotal",
                                          "lbl_check_cartsubtotal"
                                        )
                                      : "Cart Subtotal"}
                                  </span>
                                </td>

                                <td className="order-subtotal-price">
                                  <span className="order-subtotal-amount">
                                    {GetDefaultCurrencySymbol()}
                                    {makePriceRoundToTwoPlaces(CartSubTotal)}
                                  </span>
                                </td>
                              </tr>

                              <tr>
                                <td className="order-shipping" colSpan={3}>
                                  <span id="lbl_check_shipping">
                                    {LocalizationLabelsArray.length > 0
                                      ? replaceLoclizationLabel(
                                          LocalizationLabelsArray,
                                          "Shipping",
                                          "lbl_check_shipping"
                                        )
                                      : "Shipping"}
                                  </span>
                                </td>

                                <td className="shipping-price">
                                  <span>
                                    {GetDefaultCurrencySymbol()}
                                    {makePriceRoundToTwoPlaces(
                                      ShippingSubTotal
                                    )}
                                  </span>
                                </td>
                              </tr>

                              <tr>
                                <td className="total-price" colSpan={3}>
                                  <span id="lbl_check_ordtotal">
                                    {LocalizationLabelsArray.length > 0
                                      ? replaceLoclizationLabel(
                                          LocalizationLabelsArray,
                                          "Order Total",
                                          "lbl_check_ordtotal"
                                        )
                                      : "Order Total"}
                                  </span>
                                </td>

                                <td className="product-subtotal">
                                  <span className="subtotal-amount">
                                    {OrderTotalAfterDiscount != undefined &&
                                    OrderTotalAfterDiscount > 0 ? (
                                      <>
                                        <del>
                                          {GetDefaultCurrencySymbol()}{" "}
                                          {makePriceRoundToTwoPlaces(
                                            OrderTotal
                                          )}
                                        </del>
                                        &nbsp; &nbsp;{" "}
                                        {GetDefaultCurrencySymbol()}
                                        {makePriceRoundToTwoPlaces(
                                          OrderTotalAfterDiscount
                                        )}
                                      </>
                                    ) : (
                                      `${GetDefaultCurrencySymbol()} ${makePriceRoundToTwoPlaces(
                                        OrderTotal
                                      )}`
                                    )}
                                  </span>
                                </td>
                              </tr>

                              <tr
                                style={{
                                  display: IsAlreadyDiscountApplied
                                    ? "none"
                                    : "",
                                }}
                              >
                                <td className="total-price" colSpan={3}>
                                  <div className="login-form">
                                    <div
                                      className="form-group"
                                      style={{ marginBottom: "0" }}
                                    >
                                      <input
                                        type="text"
                                        name="phone"
                                        className={`form-control ${
                                          IsCouponCodeApplied
                                            ? CouponCodeCssClass
                                            : ""
                                        }`}
                                        placeholder="Enter Coupon Code"
                                        value={CouponCode}
                                        onChange={(e) =>
                                          setCouponCode(e.target.value)
                                        }
                                        maxLength={30}
                                      />
                                    </div>
                                  </div>
                                </td>

                                <td className="product-subtotal">
                                  <button
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      GetCouponCodeInfo();
                                    }}
                                    id="lbl_check_applycpn"
                                  >
                                    {LocalizationLabelsArray.length > 0
                                      ? replaceLoclizationLabel(
                                          LocalizationLabelsArray,
                                          "Apply Coupon",
                                          "lbl_check_applycpn"
                                        )
                                      : "Apply Coupon"}
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>

                  <div className="payment-method">
                    <div className="row login-form">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>
                            {LocalizationLabelsArray.length > 0
                              ? replaceLoclizationLabel(
                                  LocalizationLabelsArray,
                                  "Payment Method",
                                  "lbl_check_paymethod"
                                )
                              : "Payment Method"}
                          </label>

                          <select
                            className="form-control"
                            name="PaymentMethod"
                            value={PaymentMethod}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                          >
                            <option value="5" selected>
                              Credit Card
                            </option>
                            <option value="6">Cash on delivery</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  {PaymentMethod === "5" && (
                    <div>
                      <form className="checkout-form">
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <label>
                              {LocalizationLabelsArray.length > 0
                                ? replaceLoclizationLabel(
                                    LocalizationLabelsArray,
                                    "Card number",
                                    "lbl_check_country"
                                  )
                                : "Card number"}
                            </label>
                            <input
                              type="number"
                              name="number"
                              className="form-control"
                              // value={cardFormData.number}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>
                                {LocalizationLabelsArray.length > 0
                                  ? replaceLoclizationLabel(
                                      LocalizationLabelsArray,
                                      "Expiry Month",
                                      "lbl_check_fname"
                                    )
                                  : "Expiry Month"}
                              </label>
                              <input
                                type="number"
                                name="expiry_month"
                                className="form-control"
                                onChange={handleChange}
                                value={cardFormData.expiry_month}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>
                                {LocalizationLabelsArray.length > 0
                                  ? replaceLoclizationLabel(
                                      LocalizationLabelsArray,
                                      "Expiry Year",
                                      "lbl_check_lname"
                                    )
                                  : "Expiry Year"}
                              </label>
                              <input
                                type="number"
                                name="expiry_year"
                                className="form-control"
                                onChange={handleChange}
                                value={cardFormData.expiry_year}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 mt-2">
                          <div className="form-group">
                            <label>
                              {LocalizationLabelsArray.length > 0
                                ? replaceLoclizationLabel(
                                    LocalizationLabelsArray,
                                    "CVV",
                                    "lbl_check_lname"
                                  )
                                : "CVV"}
                            </label>
                            <input
                              type="number"
                              name="cvv"
                              className="form-control"
                              onChange={handleChange}
                              value={cardFormData.cvv}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  )}

                  <>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id="lbl_check_placeorder"
                    >
                      {LocalizationLabelsArray.length > 0
                        ? replaceLoclizationLabel(
                            LocalizationLabelsArray,
                            "Place Order",
                            "lbl_check_placeorder"
                          )
                        : "Place Order"}
                    </button>
                  </>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>

      <BestFacilities />
    </>
  );
};

export default Checkout;

const InputField = ({ type, name, placeholder, value, onChange }) => {
  return (
    <div className="checkout-form-input">
      <label>{placeholder}</label>
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
