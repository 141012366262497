
import { MakeApiCallAsync } from "./ApiHelpers";
import Config from "./Config";
import { checkIfStringIsEmtpy } from "./ValidationHelper";

export const RedirectToWhatsAppPage = () => {
    window.open('https://wa.me/923128545494', '_blank');
}



export const GetDefaultCurrencySymbol = () => {
    let DefaultCurrencySymbol = "$";  //--USD is consider as default if there is no setting in appsetting.json file
    DefaultCurrencySymbol = Config.APP_SETTING['DefaultCurrencySymbol'] ?? "$";
    return DefaultCurrencySymbol;


}

export const GetDefaultCurrencyCode = () => {
    let DefaultCurrencyCode = "USD";  //--USD is consider as default if there is no setting in appsetting.json file
    DefaultCurrencyCode = Config.APP_SETTING['DefaultCurrencyCode'] ?? "USD";
    return DefaultCurrencyCode;


}

export const GetCardType = (cardNumber) => {
  if (!cardNumber) return "UNKNOWN";

  // Remove any non-numeric characters (e.g., spaces, dashes)
  const sanitizedNumber = cardNumber.replace(/\D/g, "");

  const cardPatterns = [
    { type: "AMEX", regex: /^3[47][0-9]{13}$/ }, // Starts with 34 or 37, 15 digits
    { type: "CUP", regex: /^62[0-9]{14,17}$/ }, // Starts with 62, 16-19 digits
    { type: "DINERS", regex: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/ }, // Diners Club (varied patterns), 14 digits
    {
      type: "DISCOVER",
      regex:
        /^6(?:011|5[0-9]{2}|4[4-9][0-9]|22[1-9]|222[1-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12,15}$/,
    }, // Discover
    {
      type: "ELO",
      regex:
        /^((4011(78|79)|431274|438935|451416|457631|457632|504175|506(699|7[0-6][0-9]|77[0-8])|509[0-9]{3}|627780|636297|636368|650[4-9][0-9]{2}|6516[0-9]{2}|6550[0-9]{2}|655[1-5][0-9]{2}))[0-9]{10}$/,
    }, // ELO
    { type: "ELV", regex: /^[0-9]{13,19}$/ }, // Generic German ELV cards (length check only)
    { type: "HIPERCARD", regex: /^(606282|3841)[0-9]{10,13}$/ }, // Hipercard
    { type: "JCB", regex: /^(?:2131|1800|35\d{3})\d{11,15}$/ }, // JCB
    { type: "MAESTRO", regex: /^(50|5[6-9]|6[0-9])[0-9]{10,17}$/ }, // Maestro (UK + International)
    { type: "MASTERCARD", regex: /^(5[1-5][0-9]{14}|2[2-7][0-9]{14})$/ }, // Mastercard (16 digits)
    { type: "VISA", regex: /^4[0-9]{12}(?:[0-9]{3})?$/ }, // Visa Classic (13 or 16 digits)
    {
      type: "VISA_ELECTRON",
      regex: /^(4026|417500|4405|4508|4844|4913|4917)[0-9]{12}$/,
    }, // Visa Electron
    { type: "VISA_VPAY", regex: /^4[0-9]{15}$/ }, // Visa V-Pay
  ];

  // Find matching card type
  const match = cardPatterns.find((pattern) =>
    pattern.regex.test(sanitizedNumber)
  );
  return match ? match.type : "UNKNOWN";
};



export const GetTokenForHeader = async () => {


    try {
        let Token = "";

        let tokenFromStorage = localStorage.getItem("Token");

        if (tokenFromStorage != null && tokenFromStorage != undefined && tokenFromStorage != "") {

            Token = tokenFromStorage;
        }
        return Token;
    }
    catch (err) {
        console.error(err.message);
        return "";
    }

}

export const GetUserIdForHeader = async () => {


    try {
        let UserID = "";

        let loginUserDataJson = localStorage.getItem("user");
        const loginUser = JSON.parse(loginUserDataJson ?? "{}");

        if (loginUser != null && loginUser != undefined && loginUser != "") {

            UserID = loginUser.UserID;
        }
        return UserID;
    }
    catch (err) {
        console.error(err.message);
        return "";
    }

}

export const setLanguageCodeInSession = async (LangCode) => {


    try {
        let lCode = LangCode ?? "en";
        localStorage.setItem("langCode", lCode);
    }
    catch (err) {
        console.error(err.message);
    }

}

export const getLanguageCodeFromSession = () => {
    let langCode = "en";

    try {
        langCode = localStorage.getItem("langCode");
    }
    catch (err) {
        console.error(err.message);
    }
    if (!checkIfStringIsEmtpy(langCode)) {
        return ("en");
    } else {
        return (langCode);
    }

}

export const GetLocalizationControlsJsonDataForScreen = async (entityId, htmlElementId = null) => {

    let responseArray = [];


    try {

        let languageCode = getLanguageCodeFromSession();


        if (languageCode == undefined || languageCode == null || languageCode == "") {
            return responseArray;
        }

        if (languageCode == "en") { //-- do not perform localization for english
            return responseArray;
        }

        if (entityId == undefined || entityId == null || entityId == "" || entityId < 1) {
            return responseArray;
        }

        const headerLocalization = {
            Accept: 'application/json',
            'Content-Type': 'application/json',

        }


        const paramLocalization = {
            requestParameters: {
                entityId: entityId,
                languageCode: languageCode,
                recordValueJson: "[]",
            },
        };



        const localizationResponse = await MakeApiCallAsync(Config.END_POINT_NAMES['GET_LOCALIZATION_CSTM_PORTAL'], Config['COMMON_CONTROLLER_SUB_URL'], paramLocalization, headerLocalization, "POST", true);
        if (localizationResponse != null && localizationResponse.data != null) {

            let finalData = JSON.parse(localizationResponse.data.data);
            responseArray = finalData.labelsJsonData;

        }

    } catch (error) {
        console.log(error.message);

    }
    return responseArray;
}


export const replaceLoclizationLabel = (labelsJsonData, defaultTxt, labelHtmlId) => {

    let labelTxt = "";
    try {


        if (labelsJsonData == null || labelsJsonData == undefined || labelsJsonData.length == 0) {
            return (defaultTxt);
        }

        let foundObj = labelsJsonData.find(elem => elem.labelHtmlId === labelHtmlId);
        labelTxt = !checkIfStringIsEmtpy(foundObj?.text) === true ? defaultTxt : foundObj?.text;
    }
    catch (err) {
        console.error(err.message);
        labelTxt = defaultTxt;
    }

    return (labelTxt);


    // try {
    //     if (htmlElementId == null || htmlElementId == undefined) { //-- if htmlElementId is null, then its mean to run for whole body
    //         for (let itm = 0; itm <= labelsJsonData.length - 1; itm ++) {
    //             let htmlElement = document.getElementById(labelsJsonData[itm].labelHtmlId);
    //             if (htmlElement != null && htmlElement != undefined && checkIfStringIsEmtpy(labelsJsonData[itm].text)) {
    //                 let labelHtmlId = labelsJsonData[itm].labelHtmlId;
    //                 document.getElementById(labelHtmlId).innerHTML = labelsJsonData[itm].text;
    //             }
    //         }
    //     }
    //     else {//--if htmlElementId param is not null then only check localization for this specific html tag

    //         let parentElement = document.getElementById(htmlElementId);

    //         for (let itm = 0; itm <= labelsJsonData.length - 1; itm++) {
    //             let htmlElement = document.getElementById(labelsJsonData[itm].labelHtmlId);
    //             if (htmlElement != null && htmlElement != undefined && checkIfStringIsEmtpy(labelsJsonData[itm].text) && parentElement.contains(htmlElement)) {
    //                 let labelHtmlId = labelsJsonData[itm].labelHtmlId;
    //                 document.getElementById(labelHtmlId).innerHTML = labelsJsonData[itm].text;
    //             }
    //         }
    //     }
    // }
    // catch (err) {
    //     console.error(err.message);

    // }

}


export const ScrollIntoSpecificDiv = (divId, behaviorParam) => {
    try {
        document.getElementById(divId).scrollIntoView({
            behavior: behaviorParam ?? 'smooth',
            // block: 'center',
            // inline: 'start'
        });
      } catch (error) {
        console.error("An error occurred:", error);
      }
}


