import React, { useState } from "react";
import { use } from "react";
import { useParams } from "react-router-dom";
import { showErrorMsg } from "../../../helpers/ValidationHelper";
import { useDispatch } from "react-redux";
import rootAction from "../../../stateManagment/actions/rootAction";
import { LOADER_DURATION } from "../../../helpers/Constants";
import { Helmet } from "react-helmet";
import Config from "../../../helpers/Config";
import SiteBreadcrumb from "../layout/SiteBreadcrumb";

const CheckoutForm = () => {
  const { ref_id } = useParams();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    cardNumber: "",
  });
  const [siteTitle, setSiteTitle] = useState(Config["SITE_TTILE"]);

  console.log(ref_id);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const placeAndConfirmCustomerOrder = (refId) => {
    try {
      console.log(refId);
    } catch (err) {
      showErrorMsg("An error occured. Please try again!");
      console.log(err.message);
      setTimeout(() => {
        dispatch(rootAction.commonAction.setLoading(false));
      }, LOADER_DURATION);
    }
  };

  return (
    <>
      <Helmet>
        <title>{siteTitle} - Checkout</title>
        <meta name="description" content={siteTitle + " - Checkout"} />
        <meta name="keywords" content="Checkout"></meta>
      </Helmet>
      <div className="checkout-form-container">
        <form className="checkout-form">
          <h3>Check Out !</h3>
          <p>Enter your credit card details to complete your order.</p>
          <InputField
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
          />
          <InputField
            type="text"
            name="cardNumber"
            placeholder="Card Number"
            value={formData.cardNumber}
            onChange={handleChange}
          />
        </form>
      </div>
    </>
  );
};

export default CheckoutForm;

const InputField = ({ type, name, placeholder, value, onChange }) => {
  return (
    <div className="checkout-form-input">
      <label>{placeholder}</label>
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
